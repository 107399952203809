<template>

  <div class="listing-progress-outer">
    <div class="listing-progress-wrapper">
      <div class="listing-progress" :style="{'width': progress, 'background': bgColor}"></div>
    </div>
  </div>
  

</template>




<script>
  
  

  export default {

    components: {

    },
    props: {
      progress: String,
      percentage: Number,
    },
    computed: {
      bgColor() {
        return this.percentage > 90 ? '#5df15d' : this.percentage > 50 ? '#6af7de' : '#6dd2fb';
      }
    },
    data() {
      return {
        
      }
    },

    methods: {

      



    }

  }
  

</script>



<style lang="scss" scoped>
  
  .listing-progress-outer {
    width: 100%;
    background: #fff;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 999;
    .listing-progress-wrapper {
      width: 95%;
      max-width: 1140px;
      height: 12px;
      background: #e6f1ff;
      margin: 12px auto;
      overflow: hidden;
      border-radius: 12px;
      // transform: translateX(-50%);
      position: relative;
      .listing-progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 12px;
        // background: #6dd2fb;
        border-radius: 12px;
        transition: 0.3s;
      }
    }
  }


</style>